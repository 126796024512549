<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>
<style>
@import "~animate.css/animate.min.css";
</style>


