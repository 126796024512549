<template>
    <v-container>
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <v-btn size="x-large">Default Elevation (2)</v-btn>
        </v-col>
  
        <v-col cols="auto">
          <v-btn elevation="4" size="x-large">Elevation 4</v-btn>
        </v-col>
  
        <v-col cols="auto">
          <v-btn elevation="8" size="x-large">Elevation 8</v-btn>
        </v-col>
  
        <v-col cols="auto">
          <v-btn elevation="12" size="x-large">Elevation 12</v-btn>
        </v-col>
  
        <v-col cols="auto">
          <v-btn elevation="16" size="x-large">Elevation 16</v-btn>
        </v-col>
  
        <v-col cols="auto">
          <v-btn elevation="20" size="x-large">Elevation 20</v-btn>
        </v-col>
  
        <v-col cols="auto">
          <v-btn elevation="24" size="x-large">Elevation 24</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>
<script>

export default {
    name: "optionCom",
    components: {
        
    },
    data: () => ({}),
}
</script>