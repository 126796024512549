import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router"
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'; // Stellen Sie sicher, dass Sie die CSS-Datei importieren

import useAPIImage from './API/useAPIImage';


const vuetify = createVuetify({
  components,
  directives,
})

createApp(App)
.provide("apiImages", useAPIImage)
.use(vuetify)
.use(router)
.mount('#app')
