import { reactive } from "vue";
import axios from 'axios';

const data = reactive({
    images: [],
    loading: false,
});

const mutations = {
    updateImages: (payload) => {
        data.images = payload.map(image => ({ ...image, show: false }));
        data.images.forEach((image) => {
            const temContent = image.content;
            image.content = `data:image/png;base64,${temContent}`;
        });
    },
    loadingPending: () => (data.loading = true),
    loadingComplete: () => (data.loading = false),
};

const actions = {
    fetch: () => {
        mutations.loadingPending();
        return axios.get('http://localhost:8080/api/image').then((response) => {
            mutations.updateImages(response.data);
            mutations.loadingComplete();
        });
    },
    getListings: () => {
        mutations.loadingPending();
        return axios.get("/api/listings").then((response) => {
            mutations.updateListings(response.data);
            mutations.loadingComplete();
        });

    },
};




export default {
    data,
    actions,
   
};
