<template>
    <v-carousel 
      hide-delimiters 
      continuous="true"
      cycle
      interval="4000"
      height="700"
      touch="true"
    >
      <v-carousel-item
        v-for="(image, index) in images"
        :key="index"
        :src="image.content"
        cover
      ></v-carousel-item>
    </v-carousel>
  </template>
  

  
<script>
//import axios from 'axios';
import { inject, computed } from "vue";
export default {
  name: 'imageCom',

  setup(){
     const apiImages = inject("apiImages");
     apiImages.actions.fetch();
     const images = computed(() => apiImages.data.images);
     return {
      images,
     }
  },
};
</script>

<style scoped>

.no-scroll {
    overflow: hidden;
  }

  .v-carousel .v-btn {
  color: red; /* Farbe hier ändern */
}

</style>


