<template>
  <v-app>
    <v-card color="grey-lighten-4" flat rounded="0">
        
      <v-app-bar app fixed >
        <v-toolbar :elevation="8">
          <v-toolbar-title class="justify-center" @click="resetAllSetting()">Photograph
          
          </v-toolbar-title>

          <v-toolbar-items>
            <v-btn icon>
              <v-icon>mdi-heart</v-icon>
            </v-btn>
            <v-app-bar-nav-icon @click="showOption = !showOption"></v-app-bar-nav-icon>
          </v-toolbar-items>
        </v-toolbar>
      </v-app-bar>
      <!---<Carousel class="no-scroll mt-4 mt-16" />-->
      <Carousel class="no-scroll mt-4 mt-16" />
      <div ref="scrollArea">
      <Image v-show="showOption" class="no-scroll mt-16" />
      <Option v-show="!showOption" class="no-scroll mt-16" />
        <v-divider></v-divider>
      </div>
      <v-footer class="justify-center mt-16" color="blue-grey-lighten-3"> 
        <Connection/>    
    </v-footer>
    <endPart/>
    </v-card>
  </v-app>
</template>


<script>
import Connection from "@/components/Connection.vue";
import Image from "@/components/Image.vue";
import endPart from "@/components/endPart.vue";
import Option from "@/components/option.vue";
import Carousel from "@/components/Carousel.vue";
export default {
  name: "menuCom",
  components: {
    Connection,
    Image,
    endPart,
    Option,
    Carousel
  },
  data() {
    return {
        showOption: true,
    };
  },
  methods: {
    resetAllSetting(){
      this.showOption = true;
      this.$refs.scrollArea.scrollTop = 0;
    },
  },
  created() {},
};
</script>

<style scoped>
.no-scroll {
  overflow: hidden;
}
</style>