 <!-- Informationsbereich -->
 <template>
    <v-container>
      <v-card color="blue-grey-darken-3" class="rounded-shaped" elevation="24">
        <v-card-text>
          <v-row align="start" justify="start">
            <v-col class="mb-n8" cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
              <div>
                <v-card-subtitle>Kontakte</v-card-subtitle>
                <v-card-text class="ml-1 mt-n3">
                  123 Lorem Ipsum Street,<br>
                  Apt. 456,<br>
                  Lorem City,<br>
                  12345 Ipsumland
                </v-card-text>
              </div>
            </v-col>
            <v-col  class="mb-n8" cols="12" xs="12" sm="6" md="6" lg="3" xl="2">
              <div>
                <v-card-subtitle>E-Mail:</v-card-subtitle>
                <v-card-text class="ml-1 mt-n3">
                  example@example.com
                </v-card-text>
              </div>
            </v-col>
            <v-col  class="mb-n8" cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
              <div>
                <v-card-subtitle>Telefon:</v-card-subtitle>
                <v-card-text class="ml-1 mt-n3">
                  +49 8123 4567890
                </v-card-text>
              </div>
            </v-col>
            <v-col class="mb-n8" cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
              <div>
                <v-card-subtitle>Leistung:</v-card-subtitle>
                <v-card-text class="ml-1 mt-n3 ">
                    Leistung 1 <br>
                    Leistung 2 <br>
                </v-card-text>
              </div>
            </v-col>
            <v-col >
              <div>
                <v-img
                    class="mx-auto rounded-lg"
                    max-width="400"
                    src="https://picsum.photos/500/300?image=232"
                > </v-img>
              </div>
            </v-col>
            <v-divider></v-divider>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </template>
<script>

export default {
  name: 'conCom',
  data () {
    return {
       

    };
  },
  methods: {
    
  },
  created() {
    
  }
};
</script>
