<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col
        v-for="image in images"
        :key="image.id"
        class="d-flex child-flex px-5 py-5"
        cols="12"
        xs="12"
        sm="6"
        md="3"
        lg="4"
        xl="2"
      >
        <transition>
          <v-card
            class="mt-3 rounded-xl"
            align="center"
            justify="center"
            width="500"
            elevation="5"
            hover="true"
          >
            <v-img
              :src="image.content"
              aspect-ratio="1"
              cover
              class="bg-grey-lighten-2 shadow align-end justify-start"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,0.3)"
              @click="toggleShow(image.id)"
            >
              <v-card-title class="text-white" align="start">
                {{ image.title }}
              </v-card-title>
            </v-img>

            <!-- v-expand-transition umschließt nur den Text, der ein- und ausgeblendet werden soll -->
            <v-expand-transition>
              <div v-show="image.show">
                <v-divider></v-divider>
                <v-card-text>
                  I'm a thing. But, like most politicians, he promised more than
                  he could deliver. You won't have time for sleeping, soldier,
                  not with all the bed making you'll be doing. Then we'll go
                  with that data file! Hey, you add a one and two zeros to that
                  or we walk! You're going to do his laundry? I've got to find a
                  way to escape.
                </v-card-text>
              </div>
            </v-expand-transition>
          </v-card>
        </transition>
      </v-col>
    </v-row>
  </v-container>
</template>

  
<script>
import { inject, computed } from "vue";

export default {
  name: "imageCom",

  setup() {
    const apiImages = inject("apiImages");
    apiImages.actions.fetch();
    const images = computed(() => apiImages.data.images);

    const toggleShow = (imageId) => {
      images.value.forEach((image) => {
        if (image.id === imageId) {
          image.show = !image.show;
        } else {
          image.show = false; // Schließen Sie alle anderen Texte
        }
      });
    };
    return {
      images,
      toggleShow,
    };
  },
};
</script>

  // data () {
  //   return {
  //     images: [], // Hier speichern Sie die Bild-URLs aus Ihrem Backend
  //     show:false
        
  //   };
  // },
  // methods: {
  //   async fetchImage() {
  //     try {
  //       const response = await axios.get('http://localhost:8080/api/image');
  //       this.images = response.data; // Annahme, dass das Backend ein Array von Bild-URLs zurückgibt
  //       this.images = response.data.map(image => ({ ...image, show: false }));
  //   } catch (error) {
  //       console.error('Error fetching images:', error);
  //     }
  //   },
  //   getImageSrc(image) {
  //     // Annahme: image.content enthält Base64-Strings der Bilder
  //     return `data:image/png;base64,${image}`;
  //   },
  //   toggleShow(imageId) {
  //     this.images.forEach(image => {
  //       if (image.id === imageId) {
  //         image.show = !image.show;
  //       } else {
  //         image.show = false; // Schließen Sie alle anderen Texte
  //       }
  //     });
  //   },
  // },
  // created() {
  //   this.fetchImage();
  // }


<style scoped>
.no-scroll {
  overflow: hidden;
}
</style>