import { createRouter, createWebHistory } from "vue-router";
import Menu from "@/components/Menu.vue";
import option from "@/components/option.vue";

const router = createRouter({
  history: createWebHistory(),
  routes:  [

    {path: "/", component: Menu},
    {path: "/option", component: option},

],
});

export default router;